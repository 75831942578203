var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search-term" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.searchInfo }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入规格名称" },
                    model: {
                      value: _vm.searchInfo.keyword,
                      callback: function($$v) {
                        _vm.$set(_vm.searchInfo, "keyword", $$v)
                      },
                      expression: "searchInfo.keyword"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "请选择状态" },
                      model: {
                        value: _vm.searchInfo.status,
                        callback: function($$v) {
                          _vm.$set(_vm.searchInfo, "status", $$v)
                        },
                        expression: "searchInfo.status"
                      }
                    },
                    _vm._l(_vm.statusOption, function(item) {
                      return _c("el-option", {
                        key: item.ID,
                        attrs: { label: item.name, value: item.ID }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("查询")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.openDialog }
                    },
                    [_vm._v("新增卡券SKU")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "multipleTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            stripe: "",
            "tooltip-effect": "dark"
          },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", {
            attrs: { label: "货号", prop: "id", "min-width": "10%" }
          }),
          _c("el-table-column", {
            attrs: { label: "卡券SKU名称", prop: "name", "min-width": "25%" }
          }),
          _c("el-table-column", {
            attrs: { label: "状态", prop: "status", "min-width": "10%" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status === 1
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("上架")
                        ])
                      : scope.row.status === -1
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("下架")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "关联供应商SKU",
              prop: "sku_infos",
              "min-width": "50%"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.sku_infos, function(item, index) {
                    return _c(
                      "el-tag",
                      {
                        key: index,
                        staticStyle: { margin: "10px" },
                        attrs: { type: index % 2 == 0 ? "" : "success" }
                      },
                      [_vm._v(_vm._s(item.name))]
                    )
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", "min-width": "15%" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "table-button",
                        attrs: { size: "medium", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.updateEquitySpecs(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    scope.row.status === 1
                      ? _c(
                          "el-button",
                          {
                            staticClass: "table-button",
                            staticStyle: { margin: "10px" },
                            attrs: { size: "medium", type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.changeStatus(scope.row)
                              }
                            }
                          },
                          [_vm._v("下架")]
                        )
                      : _c(
                          "el-button",
                          {
                            staticClass: "table-button",
                            staticStyle: { margin: "10px" },
                            attrs: { size: "medium", type: "success" },
                            on: {
                              click: function($event) {
                                return _vm.changeStatus(scope.row)
                              }
                            }
                          },
                          [_vm._v("上架")]
                        )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        style: { float: "right", padding: "20px" },
        attrs: {
          "current-page": _vm.page,
          "page-size": _vm.pageSize,
          "page-sizes": [10, 30, 50, 100],
          total: _vm.total,
          layout: "total, sizes, prev, pager, next, jumper"
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "60%",
            "before-close": _vm.closeDialog,
            visible: _vm.dialogFormVisible,
            title: _vm.type == "update" ? "编辑规格" : "创建规格"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "specsForm",
              attrs: {
                model: _vm.formData,
                "label-position": "top",
                "label-width": "100px",
                rules: _vm.specsRules
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "名称:", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "60%" },
                    attrs: { clearable: "", placeholder: "请输入卡券SKU名称" },
                    model: {
                      value: _vm.formData.name,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "关联供应商商品库", prop: "sku_infos" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "110px", "margin-right": "30px" },
                      attrs: { filterable: "", placeholder: "请选择" },
                      on: { change: _vm.changeSupplier },
                      model: {
                        value: _vm.supplierName,
                        callback: function($$v) {
                          _vm.supplierName = $$v
                        },
                        expression: "supplierName"
                      }
                    },
                    _vm._l(_vm.scoptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  ),
                  _c("el-autocomplete", {
                    staticClass: "inline-input",
                    staticStyle: { width: "395px", "margin-right": "30px" },
                    attrs: {
                      "value-key": "selectName",
                      "fetch-suggestions": _vm.querySearch,
                      placeholder: "请输入内容",
                      "trigger-on-focus": false
                    },
                    on: { select: _vm.handleSelect },
                    model: {
                      value: _vm.supplierValue,
                      callback: function($$v) {
                        _vm.supplierValue = $$v
                      },
                      expression: "supplierValue"
                    }
                  }),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addSupplier }
                    },
                    [_vm._v("添加")]
                  ),
                  _c(
                    "el-table",
                    {
                      staticStyle: { "margin-top": "30px" },
                      attrs: { data: _vm.formData.sku_infos }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "scName",
                          label: "供应商名称",
                          width: "100"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "id", label: "ID", width: "80" }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "name",
                          label: "供应商商品名称列表",
                          width: "200"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "salePrice", label: "售价", width: "80" }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "scCostPrice",
                          label: "成本价",
                          width: "90"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "stock", label: "库存数量", width: "80" }
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "level", label: "排序", width: "90" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入内容" },
                                  model: {
                                    value: scope.row.level,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "level", _vm._n($$v))
                                    },
                                    expression: "scope.row.level"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作", width: "100" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      type: "danger",
                                      icon: "el-icon-delete",
                                      size: "mini"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteSupplier(scope.$index)
                                      }
                                    },
                                    slot: "reference"
                                  },
                                  [_vm._v("删除")]
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.enterDialog } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }